<template>
  <div>
     <div class="cases">
       <div class="caseslogo">
           <img :src="bannerimg" width="100%">
       </div>
    <div class="sport">
      <div class="product_title">
        <div class="product_tit">
          <div class="tit1">数字文娱</div>
          <div class="tit2"></div>
        </div>
      </div>

      <div class="sportswiper">
        <swiper ref="numberSwiper" :options="swiperOption1" id="myswiper">
          <swiper-slide v-for="(item,index) in casearr" :key="index">
            <div class="swiper_con">
              <img :src="item.articleCover" alt="" />
              <span>{{item.articleTitle}}</span>
            </div>
          </swiper-slide>

          <div class="swiper-pagination my_bullet" slot="pagination"></div>
          <!-- <div class="swiper-button-prev" slot="button-prev"><img  src="../assets/images/01首页/上一页1.png" alt=""></div>
        <div class="swiper-button-next" slot="button-next"><img src="../assets/images/01首页/下一页1.png" alt=""></div> -->
        </swiper>
        <div class="swiper-list">
           <div class="swiper-button-prev-diy" @click="numberPrevFn"></div>
        <div class="swiper-button-next-diy" @click="nmuberNextFn"></div>
        </div>
       
      </div>
    </div>
    <div class="sport">
      <div class="product_title">
        <div class="product_tit">
          <div class="tit1">虚拟仿真</div>
          <div class="tit2"></div>
        </div>
      </div>

      <div class="sportswiper">
        <swiper ref="xnSwiper" :options="swiperOption2" id="myswiper" >
          <swiper-slide v-for="(item,index) in xncasearr" :key="index">
            <div class="swiper_con">
              <img :src="item.articleCover" alt="" />
              <span>{{item.articleTitle}}</span>
            </div>
          </swiper-slide>

          <div class="swiper-pagination my_bullet" slot="pagination"></div>
          <!-- <div class="swiper-button-prev" slot="button-prev"><img  src="../assets/images/01首页/上一页1.png" alt=""></div>
        <div class="swiper-button-next" slot="button-next"><img src="../assets/images/01首页/下一页1.png" alt=""></div> -->
        </swiper>
        <div  class="swiper-list">
                  <div class="swiper-button-prev-diy" @click="xnPrevFn"></div>
        <div class="swiper-button-next-diy" @click="xnNextFn"></div>
        </div>

      </div>
    </div>
    <div class="sport">
      <div class="product_title">
        <div class="product_tit">
          <div class="tit1">运动捕捉</div>
          <div class="tit2"></div>
        </div>
      </div>
   
      <div class="sportswiper">
        <swiper ref="mySwiper" :options="swiperOption66"  id="myswiper" >
          <swiper-slide v-for="(item,index) in sportlist" :key="index">
            <div class="swiper_con">
              <img :src="item.articleCover" alt="" />
              <span>{{item.articleTitle}}</span>
            </div>
          </swiper-slide>

          <div class="swiper-pagination my_bullet" slot="pagination"></div>
          <!-- <div class="swiper-button-prev" slot="button-prev"><img  src="../assets/images/01首页/上一页1.png" alt=""></div>
        <div class="swiper-button-next" slot="button-next"><img src="../assets/images/01首页/下一页1.png" alt=""></div> -->
        </swiper>
          <div  class="swiper-list">
              <div class="swiper-button-prev-diy" @click="prevFn"></div>
        <div class="swiper-button-next-diy" @click="nextFn"></div>
          </div>
      
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import {
  getbannerimg,
  getbannerdetail,

} from "@/http/api/user.js";
export default {
data() {
  return {
    bannerimg:'',
   // 数字文娱
      casearr: [],
      // 虚拟仿真
      xncasearr: [],
       // 运动捕捉标题
      titlist: [],
      // 运动捕捉下标
      currindex: 0,
      // 运动捕捉列表
      sportlist: [
      ],
      // 轮播
      swiperOption1: {
          slidesPerView: 1,
        spaceBetween: 30,
        slidesPerGroup: 1,

        slidesPerColumn: 3,
        slidesPerColumnFill: "row",
        // 设置分页器
        pagination: {
          el: ".swiper-pagination",
          // bulletClass: "my-bullet",
          // 设置点击可切换
          clickable: true,
        },
        // 设置前进后退按钮
        navigation: {
          nextEl: ".sportswiper .swiper-button-next",
          prevEl: ".sportswiper .swiper-button-prev",
        },
        // 设置自动轮播
        autoplay: {
          delay: 3000, // 5秒切换一次
        },
        // // 设置轮播可循环
        // loop: true,
      },
      swiperOption2: {
         slidesPerView: 1,
        spaceBetween: 30,
        slidesPerGroup: 1,

        slidesPerColumn: 3,
        slidesPerColumnFill: "row",
        // 设置分页器
        pagination: {
          el: ".swiper-pagination",
          // bulletClass: "my-bullet",
          // 设置点击可切换
          clickable: true,
        },
        // 设置前进后退按钮
        navigation: {
          nextEl: ".sportswiper .swiper-button-next",
          prevEl: ".sportswiper .swiper-button-prev",
        },
        // 设置自动轮播
        autoplay: {
          delay: 3000, // 5秒切换一次
        },
        // // 设置轮播可循环
        // loop: true,
      },
    swiperOption66: {
         slidesPerView: 1,
        spaceBetween: 30,
        slidesPerGroup: 1,

        slidesPerColumn: 3,
        slidesPerColumnFill: "row",
        // 设置分页器
        pagination: {
          el: ".swiper-pagination",
          // bulletClass: "my-bullet",
          // 设置点击可切换
          clickable: true,
        },
        // 设置前进后退按钮
        navigation: {
          nextEl: ".sportswiper .swiper-button-next",
          prevEl: ".sportswiper .swiper-button-prev",
        },
        // 设置自动轮播
        autoplay: {
          delay: 3000, // 5秒切换一次
        },
        // // 设置轮播可循环
        // loop: true,
      },
  };
},
mounted() {
  this.getcompany()
      // 数字文娱.
    this.getEntertainment();
    // 虚拟仿真
    this.getSimulation()
    // 运动捕捉
    this.getSport()
},
computed: {
    // 数字文娱
    numberSwiper() {
      return this.$refs.numberSwiper.$swiper;
    },
    // 虚拟仿真
    xnSwiper() {
      return this.$refs.xnSwiper.$swiper;
    },
    // 运动捕捉
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  methods: {
     //获取公司简介
    async getcompany() {
      let params = {
        barId:10,
      };
      const data = await getbannerdetail(params);
      this.bannerimg='http://36.152.65.166:10008'+data.data.image
     
      // console.log(this.bannerimg);
      
    },
     //获取数字文娱
    async getEntertainment() {
      let params = {
        barId: 1,
      };
      const data = await getbannerdetail(params);
      this.casearr = data.data.portalArticleList[1].children;
      this.casearr.forEach((r) => {
        r.articleCover = "http://36.152.65.166:10008" + r.articleCover;
      });
    },
    //数字文娱分页
    numberPrevFn() {
      this.numberSwiper.slidePrev();
    },
    nmuberNextFn() {
      this.numberSwiper.slideNext();
    },
    //获取虚拟仿真
    async getSimulation() {
      let params = {
        barId: 7,
      };
      const data = await getbannerdetail(params);
      this.xncasearr = data.data.children[0].portalArticleList;
      this.xncasearr.forEach((r) => {
        r.articleCover = "http://36.152.65.166:10008" + r.articleCover;
      });
    },
    // 虚拟仿真分页
    xnPrevFn() {
      this.xnSwiper.slidePrev();
    },
    xnNextFn() {
      this.xnSwiper.slideNext();
    },

    //获取运动捕捉列表
    async getSport() {
     let params = {
        barId: 1,
      };
      const data = await getbannerdetail(params);

      this.titlist = data.data.portalArticleList[17].children;
 
      this.sportlist = data.data.portalArticleList[20].children;
      this.sportlist.forEach(r=>{
        r.articleCover='http://36.152.65.166:10008'+r.articleCover
      })
   
    },
    // 运动捕捉标题
    addclass(index) {
 
      this.currindex = index;
    },
    // 运动捕捉分页
    prevFn() {
      this.swiper.slidePrev();
    },
    nextFn() {
      this.swiper.slideNext();
    },
  },
}
</script>

<style>

</style>
<style lang="scss" scoped>
.my_bullet {
  height: 4% !important;
  bottom: -5% !important;
}
.cases{

.sport {
  .product_title {
  width: 7.5rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .product_tit {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0.6rem;
    .tit1 {
      font-size: 0.3rem;
      font-weight: bold;
      margin-bottom: 0.13rem;
    }
    .tit2 {
      width: 0.6rem;
      height: 0.04rem;
      background-color: #0088ea;
    }
  }
  p {
    display: inline-block;
    width: 6rem;
    font-size: 0.24rem;
    text-align: center;
  }
}
  // height: 11rem;
  .sportswiper {
    width: 7rem;

    overflow: hidden;
    margin: 0 auto;
    position: relative;
    .swiper-button-prev-diy,
    .swiper-button-next-diy {
      width: 0.5rem;
      height: 0.5rem;
      position: absolute;
      bottom: 0px;
      transform: translate(0, -50%);
      -webkit-transform: translate(0, -50%);
    }
    .swiper-button-prev-diy {
      left: 1.5rem;

      z-index: 9999;
      background: url("~@/assets/images/01首页/上一页1.png") no-repeat;
      background-size: 100% 100%;
    }
    .swiper-button-prev-diy:hover {
      cursor: pointer;
      background: url("~@/assets/images/01首页/上一页2.png") no-repeat;
      background-size: 100% 100%;
    }
    .swiper-button-next-diy:hover {
      cursor: pointer;
      background: url("~@/assets/images/01首页/下一页2.png") no-repeat;
      background-size: 100% 100%;
    }
    .swiper-button-next-diy {
      z-index: 9999;
      right: 1.5rem;
      background: url("~@/assets/images/01首页/下一页1.png") no-repeat;
      background-size: 100% 100%;
    }
    .swiper_con {
      width: 100%;
      height: 5.4rem;
      border: 0.02rem solid #0088ea;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 0.1rem;
      img {
        width: 100%;
      }
      span {
        height: 0.85rem;
        line-height: 0.85rem;
        font-size: 0.18rem;
        color: #000000;
      }
    }
  }
  .swiper_tit {
    width: 7rem;
    margin: 0 auto;
    ul {
      padding: 0;
      display: flex;
      justify-content: space-evenly;
      margin-bottom: 0.57rem;
      margin-top: 0.44rem;
      li {
        cursor: pointer;
        width: 2.36rem;
        height: 0.6rem;
        background-color: #e5f3fd;
        color: #000000;
        font-size: 0.24rem;
        display: flex;
        align-items: center;
        justify-content: center;
        div {
          margin-right: 0.18rem;
        }
        .swiper_img1 {
          width: 0.3rem;
          height: 0.2rem;
          background: url("~@/assets/images/01首页/产品系统2.png") no-repeat;
        }
        .swiper_img2 {
          width: 0.3rem;
          height: 0.34rem;
          background: url("~@/assets/images/01首页/解决方案1.png") no-repeat;
        }
        .swiper_img3 {
          width: 0.3rem;
          height: 0.3rem;
          background: url("~@/assets/images/01首页/项目案例1.png") no-repeat;
        }
      }
      .blue {
        background-color: #0088ea;
        cursor: pointer;
        color: white;
        .swiper_img1 {
          background: url("~@/assets/images/01首页/产品系统1.png") no-repeat;
        }
        .swiper_img2 {
          background: url("~@/assets/images/01首页/解决方案 2.png") no-repeat;
        }
        .swiper_img3 {
          background: url("~@/assets/images/01首页/项目案例2.png") no-repeat;
        }
      }
    }
  }
}
}

</style>